import { Container, ClientsText, StoresContainer } from './_Clients.js';
import phoneLayersImg from '../../assets/phoneLayers.png';
import appleStoreBtnImg from '../../assets/appleStoreBtn.png';
import googlePlayBtnImg from '../../assets/googlePlayBtn.png';
import { Fade } from 'react-reveal';

export function Clients() {
    return (
        <Container>
            <Fade bottom>
                <img src={phoneLayersImg} />
                <ClientsText>
                    <div>
                        <div>
                            <span/>
                            <h5>CLIENTE</h5>
                        </div>
                        <p>
                            Com o nosso app você consegue fazer o seu próprio atendimento em qualquer estabelecimento cadastrado na nossa plataforma de maneira simples, fácil, rápida e intuitiva, sem depender de mais ninguém.
                        </p>
                    </div>
                    <StoresContainer>
                        <img src={appleStoreBtnImg} alt="botão da apple store" />
                        <img src={googlePlayBtnImg} alt="botão da google play" />
                    </StoresContainer>
                </ClientsText>
            </Fade>
        </Container>
    )
}
