import { About } from "../About/About";
import { Clients } from "../Clients/Clients";
import { Establishments } from "../Establishments/Establishments";
import { Header } from "../Header/Header";
import { HowItWorks } from "../HowItWorks/HowItWorks";
import { OurDifferential } from "../OurDifferential/OurDifferential";

export function LandingPage() {
  return (
    <>
     <Header />
     <About />
     <OurDifferential />
     <Clients />
     <Establishments />
     <HowItWorks />
    </>
  )
}