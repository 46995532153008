import React, { Component } from "react";
import Slider from "react-slick";
import { Container, CardContainer, Card  } from './_Carousel';

import adminstrationImg from '../../../assets/adminstration.png';
import machineImg from '../../../assets/machine.png';
import touchImg from '../../../assets/touch.png';
import { Fade } from 'react-reveal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

export default class Carousel extends Component {
  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 818,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 778,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 486,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false
          }
        }
      ]
    };
    return (
      <Container>
        <h2> Nossos diferenciais: </h2>
        <Fade>
          <Slider {...settings}>
            <CardContainer>
              <Card>
                <img src={adminstrationImg} />
                <h3>Economia de até 60% com equipe</h3>
                <p> Você consegue economizar até 60% nos seus gastos com funcionários.</p>
              </Card>
            </CardContainer>
            <CardContainer>
              <Card>
                <img src={touchImg} />
                <h3>⁠Comandas separadas</h3>
                <p>Acabamos com o problema histórico de um cliente pagar por algo que não pediu.</p>
              </Card>
            </CardContainer>
            <CardContainer>
              <Card>
                <img src={machineImg} />
                <h3>Reserva de mesa</h3>
                <p>Você poderá habilitar reservas de mesas para seus clientes (caso trabalhe com essa modalidade).</p>
              </Card>
            </CardContainer>
            <CardContainer>
              <Card>
                <img src={adminstrationImg} />
                <h3>Campanha de notificação push</h3>
                <p>Temos a possibilidade de disparar notificação push para os usuários cadastrados sobre promoções ou novidades no seu restaurante.</p>
              </Card>
            </CardContainer>
            <CardContainer>
              <Card>
                <img src={machineImg} />
                <h3>Aumento de ticket médio</h3>
                <p>Como o  usuário tem o cardápio na mão com fácil acesso ele consome mais.</p>
              </Card>
            </CardContainer>
            <CardContainer>
              <Card>
                <img src={touchImg} />
                <h3>Integração com o pdv da Totvs</h3>
                <p>Temos integração com o sistema totvs.</p>
              </Card>
            </CardContainer>
          </Slider>
        </Fade>
      </Container>
    );
  }
}