import styled from 'styled-components';

export const Container = styled.div`
  background: var(--main);
  border-bottom-right-radius: 533px;
  height: 770px;

  > img {
      margin-top: 62.55px;
      margin-left: 94px;
    }

    @media (max-width: 992px) {
      height: 663px;
    }

    @media (max-width: 770px) {
      border-bottom-right-radius: 0;

      > img {
        display: table;
        margin: 0 auto;
        padding: 35px 0 35px 0;
      }
    }

    @media (max-width: 425px) {
      height: 900px;
    }

    @media (max-width: 372px) {
      height: 800px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 6%;
    height: 616px;

    > img {
      margin-top: 62.55px;
    }

    @media (max-width: 425px) {
      display: none;
    }
`;

export const StoresContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 106px;

  div:nth-child(1)  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .appleStoreBtn {
        margin: 0 0 8px 0;
    }
    
    > img {
      cursor: pointer;
    }
  }
  div:nth-child(2)  {
    display: flex;
    align-items: center;
    color: white;
    margin-left: 20px;

    > p {
      margin-right: 21px;
    }

    >  button {
        background: transparent;
        border: 1px solid #FFFFFF;
        border-radius: 27px;
        color: #fff;
        padding: 0 60px 0 60px;
        height: 54px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    > button:hover {
        background: #FFFFFF;
        color: var(--main);
        font-weight: bold;
    }
  }

  @media (max-width: 770px) {
    div:nth-child(2)  {
      >  button {
        width: 258px;
        padding: 0;
      }
    }
  }

  @media (max-width: 530px) {
    div:nth-child(2)  {
      >  button {
        width: 210px;
      }
    }
  }

  @media (max-width: 479px) {
    div:nth-child(2)  {
      >  button {
        width: 150px;
      }
    }
  }
`;

export const Title = styled.div`
  margin-top: 62.55px;
  width: 50%;

  > h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 4vw;
    color: #FFFFFF;
  }

  > h2 {
    margin-top: 4%;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 3vw;
    color: #FFFFFF;
  }

  > p {
    margin-top: 4%;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.7vw;
    color: #FFFFFF;
  }

  @media (max-width: 900px) {
    > h1 {
      font-size: 5vw;
    }

    > h2 {
      font-size: 4vw;
    }

    > p {
      font-size: 2.2vw;
    }
  }
`;

export const Devices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > h2 {
    margin: 0 10% 20px 0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
  }

  > img {
    width: 93%;
    margin-top: -35px;
  }

  @media (max-width: 788px) {
    > h2 {
      font-size: 0.8rem;
    }
    > img {
      margin-top: 0;
    }
  }
`;

export const ScrollDownBtn = styled.div`
  display: table;
  margin: -40px auto;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  border-radius: 50px;

  > img {
    margin-top: 8px;
    padding: 25px;
    cursor: pointer;
  }

  @media (max-width: 992px) {
    margin: -149px auto;
  }

  @media (max-width: 425px) {
    margin: -93px auto;
  }
`;

export const ContentMobile = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 800px;

> h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 8vw;
    color: #FFFFFF;
    text-align: center;
  }

  > h2 {
    margin-top: 4%;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 6vw;
    color: #FFFFFF;
    text-align: center;
  }

  > p {
    margin-top: 4%;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 4vw;
    color: #FFFFFF;
    text-align: center;
  }

  @media (min-width: 426px) {
    display: none;
  }

  @media (max-width: 372px) {
      height: 700px;
  }
`;

export const StoresContainerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div:nth-child(1)  {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    margin: 10px 0 10px 0;

    .appleStoreBtn {
        margin: 0 0 8px 0;
    }
    
    img:nth-child(1)  {
      margin-right: 10px;
    }
  }
  div:nth-child(2)  {
    display: flex;
    align-items: center;
    flex-direction: column  ;
    color: white;

    > p {
      margin-right: 21px;
    }

    >  button {
        background: transparent;
        border: 1px solid #FFFFFF;
        border-radius: 27px;
        color: #fff;
        padding: 0 60px 0 60px;
        height: 54px;
        margin-top: 20px;
    }

    > button:hover {
        background: #FFFFFF;
        color: var(--main);
        font-weight: bold;
    }
  }

  > h2 {
    margin-top: 20px;
    color: #ffffff;
    font-size: 13px;
    text-align: center;
  }
`;
