import { Container, EstablishmentsText } from './_Establishments.js';
import computerLayers from '../../assets/computerLayers.png';
import { Fade } from 'react-reveal';

export function Establishments() {
    return (
        <Container>
            <Fade big>
                <EstablishmentsText>
                    <div>
                        <span/>
                        <h5>ESTABELECIMENTO</h5>
                    </div>
                    <p>
                    Com o nosso sistema você consegue cadastrar o seu cardápio,
                    gerenciar as mesas, pedidos feitos e também consegue aceitar
                    reservas, tudo isso por a partir de 49,90 por mês.
                    </p>

                    <button><a href="https://estabelecimento.paymentapp.com.br/">Cadastre-se</a></button>
                </EstablishmentsText>
            </Fade>
            <img src={computerLayers} />
        </Container>
    )
}
