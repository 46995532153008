import styled from 'styled-components'

export const Main = styled.main`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 80%;
  height: 80%;
`

export const Title = styled.h1`
  margin: 5px;
  color: var(--main);
  font-size: 3vh;
`

export const Email = styled.a`
  color: var(--main);
`

export const Paragraph = styled.p`
  width: 90%;
  max-width: 800px;
  margin: 20px;
  color: var(--descripionText);
  font-size: 2.5vh;
`