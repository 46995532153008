import { 
    Container, 
    Content, 
    Title, 
    StoresContainer, 
    Devices,
    ScrollDownBtn,
    ContentMobile,
    StoresContainerMobile
 } from './_Header.js';
import logoImg from '../../assets/logo.svg';
import appleStoreBtnImg from '../../assets/appleStoreBtn.png';
import googlePlayBtnImg from '../../assets/googlePlayBtn.png';
import arrowDownImg from '../../assets/arrowDown.png';
import computerPhoneImg from '../../assets/computerPhone.png';
import computadorCelularMobile from '../../assets/computadorCelularMobile.png';
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal';

export function Header() {
    return (
        <>
            <Container>
                <img src={logoImg} alt="payment logo" />
                <Content>
                    <Title>
                    <Fade left>
                        <h1>Muito mais que pagar</h1>
                        <p>
                            Você quer economizar até 60% com funcionários? Você gostaria de aumentar seus lucros em até 50%, e que seus clientes economizassem em média 21 minutos a menos e aumentando assim sua rotatividade? Então o Payment é pra você! Com nossa solução, seu cliente poderá reservar mesas, fazer pedidos, efetuar pagamentos e muito mais!
                        </p>
                    </Fade>
                        <StoresContainer>
                            <div>
                                <img 
                                    className='appleStoreBtn' 
                                    src={appleStoreBtnImg} 
                                    alt="botão da apple store" 
                                />
                                <img src={googlePlayBtnImg} alt="botão da google play" />
                            </div>
                            <div>
                                <p>ou</p>
                                <button><a href="https://estabelecimento.paymentapp.com.br/">Cadastre seu Estabelecimento</a></button>
                            </div>
                        </StoresContainer>
                    </Title>
                    <Devices>
                        <Fade right>
                                <h2>
                                    Precisa de suporte? 
                                    <br />
                                    Entre em contato pelo email:
                                    <br /> 
                                    comercial@paymentapp.com.br
                                </h2>
                            <img src={computerPhoneImg} alt="imagem de computador" />
                        </Fade>
                    </Devices>
                </Content>
                <ContentMobile>
                    <h1>Muito mais que pagar</h1>
                    <p>
                        Você quer economizar até 60% com funcionários? Você gostaria de aumentar seus lucros em até 50%, e que seus clientes economizassem em média 21 minutos a menos e aumentando assim sua rotatividade? Então o Payment é pra você! Com nossa solução, seu cliente poderá reservar mesas, fazer pedidos, efetuar pagamentos e muito mais!
                    </p>

                    <Devices>
                        <img src={computadorCelularMobile} alt="imagem de computador" />
                    </Devices>

                    <StoresContainerMobile>
                            <div>
                                <img 
                                    className='appleStoreBtn' 
                                    src={appleStoreBtnImg} 
                                    alt="botão da apple store" 
                                />
                                <img src={googlePlayBtnImg} alt="botão da google play" />
                            </div>
                            <div>
                                <p>ou</p>
                                <button>Cadastre seu Estabelecimento</button>
                            </div>
                            <h2>
                                Precisa de suporte? Entre em contato pelo email comercial@paymentapp.com.br
                            </h2>
                        </StoresContainerMobile>
                </ContentMobile>
                <Link to="about" spy={true} smooth={true}>
                    <ScrollDownBtn>
                        <img src={arrowDownImg} />
                    </ScrollDownBtn>
                </Link>
            </Container>
        </>
    )
}
