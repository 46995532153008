import { Container } from './_OurDifferential.js';

import Carousel from './Carousel/Carousel';

export function OurDifferential() {
    return (
        <Container>
            <Carousel />
        </Container>
    )
}
