import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
  margin-left: 90px;

  > img {
    width: 50%;
    margin-top: -40px;
  }

  @media (max-width: 496px) {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    > img {
      display: table;
      width: 80%;
      margin-bottom: 30px;
      align-self: flex-end;
    }
  }

  @media (max-width: 799px) {
    margin-left: 20px;

    > img {
      width: 40%;
      margin-top: 35px;
    }
}

@media (max-width: 496px) {
  margin-left: 0;

  > img {
    width: 69%;
  }
}
`;

export const EstablishmentsText = styled.div`
  > div {
      width: 40%;
      margin: 20px 0 60px 0;

      span {
      display: block;
      background: #21E270 0% 0% no-repeat padding-box;
      border-radius: 20px 20px 0px 0px;
      height: 8px;
    }

    h5 {
      color: #38E57F;
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
    }
  }

  p {
    width: 90%;
    margin: 0 auto;
    color: #8E8E93;
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 2vw;
  }

  button {
    border: 0;
    background: var(--main);
    border-radius: 50px;
    color: #fff;
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.5vw;
    padding: 10px 35px 10px 35px;
    margin-top: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background: #fff;
    color: var(--main);
    font-weight: bold;
    border: 1px solid var(--main);
  }

  @media (max-width: 798px) {
    h3, p {
      font-size: 3vw;
    }

    button {
      font-size: 16px;
    }
  }

  @media (max-width: 496px) {
    > div {
      display: table;
      margin: 0 auto;
    }

    h3, p {
      text-align: center;
      font-size: 5vw;
      margin-top: 50px;
    }

    button {
      font-size: 16px;
      display: table;
      margin: 30px auto;
    }
  }
`;
