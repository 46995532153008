import styled from 'styled-components';

export const Container = styled.div`
    background: var(--main);
    margin-top: 6%;

    > h1 {
        font-family: 'FontsFreeNetSFProTextHeavy';
        font-size: 40px;
        color: #fff;
        text-align: center;
        padding: 60px 0 50px 0;
    }

    @media (max-width: 616px) {
        > h1 {
            font-size: 30px;
        }
    }

    @media (max-width: 425px) {
        > h1 {
            font-size: 25px;
        }
    }

    .clients {
        margin-right: 67px;
    }

    @media (max-width: 352px) {
        .clients {
            margin-right: 10px;
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;

        > div {
            height: 33px;
        }

        span {
            cursor: pointer;
            font-size: 23px;
        }

        @media (max-width: 616px) {
            span {
                font-size: 18px;
            }
        }

        @media (max-width: 425px) {
            span {
                font-size: 14px;
            }
        }

        hr {
            background: #FF2D55;
            height: 3px;
            border: 0;
            margin-top: 10px;
            width: 100px;
            display: table;
            margin: 10px auto;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 60px;

    .phoneImage {
        margin: 0  0  40px 10%; 
        width: 16%;
    }

    @media (max-width: 552px) {
        .phoneImage {
            margin: 0  0  40px 0; 
        }
    }

    .computerImage {
        width: 48%;
        height: 36%;
    }

    @media (max-width: 978px) {
        .phoneImage {
            width: 20%;
        }

        .computerImage {
            width: 50%;
        }
    }

    @media (max-width: 552px) {
        .phoneImage {
            width: 35%;
        }

        .computerImage {
            width: 70%;
        }
    }

    @media (max-width: 320px) {
        .optionsContainer {
            padding: 10px;
        }
    }

    .optionsContent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;

        > span {
            background: #FF2D55;
            height: 77px;
            width: 77px;
            border-radius: 50px;
            margin: -30px 20px 0 0;
            box-shadow: 0px 3px 18px #ff2d55;
            cursor: pointer;

            > img {
                display: table;
                margin: 22px auto;
            }
        }

        > img {
            margin: -30px 20px 0 0;
            cursor: pointer;
        }

        > div {
            > h4 {
                font-family: 'FontsFreeNetSFProTextHeavy';
                font-size: 20px;
                color: #fff;
            }

            > p {
                font-family: 'FontsFreeNetSFProTextMedium1';
                font-size: 18px;
                margin: 10px 0 40px 0;
                color: #fff;
            }

            @media (max-width: 768px) {
                > h4, p {
                    font-size: 15px;
                }
            }
        }   
    }

    @media (max-width: 552px) {
        flex-direction: column;
    }
`;