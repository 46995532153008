import { Main, Paragraph, Email, Title } from "./_Contact";

export function Contact() {
  return(
    <Main>
        <Paragraph>
          Para mais informações, dúvidas sobre o site ou o aplicativo, entrar em contato com
          o nosso departamento de
          {" "}
          <Email
            href="mailto: suporte@paymentapp.com.br"
            target="_blank"
            rel="noreferrer"
          >
            suporte.
          </Email>
        </Paragraph>
        <Title>
          Equipe Payment Agradece
        </Title>
    </Main>
  )
}