import { Container, AboutText } from './_About.js';
import girlCard from '../../assets/girlCard.png';
import { Fade } from 'react-reveal';

export function About() {
    return (
        <>
            <div id="about" style={{ position: 'relative', top: '100px' }} />
            <Container>
                <Fade left>
                    <img src={girlCard} />
                </Fade>
                <Fade right>
                    <AboutText>
                        
                            <div>
                                <span/>
                                <h5>SOBRE NÓS</h5>
                            </div>
                            <h3>
                            O Payment é uma empresa de autoatendimento que atua
                            na área de bares e restaurantes
                            </h3>
                            <p>
                            A ideia do Payment surgiu com a queixa de muitos
                            clientes com a demora na hora de fazer pedidos e
                            pagar contas em restaurantes. Temos duas modalidades
                            de funcionamento: Na primeira o cliente consegue
                            efetuar diversos pedidos e em seguida realiza o
                            pagamento no final. E na segunda modalidade (para
                            restaurantes de praças de alimentação e food
                            trucks), no qual o cliente faz o pedido e efetua o
                            pagamento em seguida, recebendo uma notificação para
                            retirar seu pedido após o restaurante finalizar.
                            </p>
                    </AboutText>
                </Fade>
            </Container>
        </>
    )
}
