import { useState } from 'react';
import { Container, Content } from './_HowItWorks.js';
import phoneImg from '../../assets/phone.png';
import noScreenImg from '../../assets/noScreen.png';
import pcAdmImg from '../../assets/pcAdm.png';
import pcMenuImg from '../../assets/pcMenu.png';
import pcSignUpImg from '../../assets/pcSignUp.png';
import pcTablesImg from '../../assets/pcTables.png';
import phoneAccountImg from '../../assets/phoneAccount.png';
import phoneOrderImg from '../../assets/phoneOrder.png';
import reservImg from '../../assets/reserv.png';
import orderImg from '../../assets/order.png';
import appImg from '../../assets/app.png';
import searchIconImg from '../../assets/searchIcon.png';
import searchImg from '../../assets/search.png';
import orderIconImg from '../../assets/orderIcon.png';
import appIconImg from '../../assets/appIcon.png';
import reservIconImg from '../../assets/reservIcon.png';
import menuImg from '../../assets/menu.png';
import solicitaionsImg from '../../assets/solicitaions.png';
import establishmentImg from '../../assets/establishment.png';
import requestImg from '../../assets/request.png';
import menuIconImg from '../../assets/menuIcon.png';
import ssolicitationsIconImg from '../../assets/solicitationsIcon.png';
import establishmentIconImg from '../../assets/establishmentIcon.png';
import requestIconImg from '../../assets/requestIcon.png';
import { Fade } from 'react-reveal';

export function HowItWorks() {
    const [phone, setPhone] = useState(0);
    const [computer, setComputer] = useState(0);
    const [deviceSelect, setDeviceSelect] = useState('clients');
    
    function renderImage() {
            if(phone === 0) {
                return (
                <Fade left>
                    <img
                        style = { 
                        deviceSelect !== 'clients' ? 
                        { display: 'none' } : 
                        { display: 'unset' }}
                        className="phoneImage" 
                        src={phoneImg} 
                    />
                    <img 
                        style = { 
                        deviceSelect === 'clients' ? 
                        { display: 'none' } : 
                        { display: 'unset' }} 
                        className="computerImage" 
                        src={pcSignUpImg} 
                    />
                </Fade>
                )
            } else if(phone === 1) {
                return (
                    <>
                        <img
                            style = { 
                            deviceSelect !== 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }}
                            className="phoneImage" 
                            src={noScreenImg} 
                        />
                        <img 
                            style = { 
                            deviceSelect === 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }} 
                            className="computerImage" 
                            src={pcMenuImg} 
                        />
                    </>
                )
            } else if(phone === 2) {
                return (
                    <>
                        <img
                            style = { 
                            deviceSelect !== 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }}
                            className="phoneImage" 
                            src={phoneOrderImg} 
                        />
                        <img 
                            style = { 
                            deviceSelect === 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }} 
                            className="computerImage" 
                            src={pcTablesImg} 
                        />
                    </>
                )
            } else if(phone === 3) {
                return (
                    <>
                        <img
                            style = { 
                            deviceSelect !== 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }}
                            className="phoneImage" 
                            src={phoneAccountImg} 
                        />
                        <img 
                            style = { 
                            deviceSelect === 'clients' ? 
                            { display: 'none' } : 
                            { display: 'unset' }} 
                            className="computerImage" 
                            src={pcAdmImg} 
                        />
                    </>
                )
            }          
    }

    function renderDevices() {
        if(deviceSelect === 'clients') {
            return (
                <div className="optionsContainer">
                    <div className="optionsContent">
                        {phone === 0 ? (
                            <span onClick={() => setPhone(0)}><img src={searchIconImg}/></span>
                        ) : (
                            <img onClick={() => setPhone(0)} src={searchImg}/>
                        )}
                        <div>
                            <h4>Localize um restaurante</h4>
                            <p>Encontre um restaurante que <br />
                            te agrade perto da sua <br />
                            localização.</p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {phone === 1 ? (
                            <span onClick={() => setPhone(1)}><img src={reservIconImg}/></span>
                        ) : (
                            <img onClick={() => setPhone(1)} src={reservImg}/>
                        )}
                        <div>
                            <h4>Faça uma reserva</h4>
                            <p>Reserve um lugar no <br />
                            restaurante escolhido com <br /> 
                            antecedência.</p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {phone === 2 ? (
                            <span onClick={() => setPhone(2)}><img src={orderIconImg}/></span>
                        ) : (
                            <img onClick={() => setPhone(2)} src={orderImg}/>
                        )}
                        <div>
                            <h4>Faça seu pedido</h4>
                            <p>Escolha seu prato através do <br />
                            nosso aplicativo!</p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {phone === 3 ? (
                            <span onClick={() => setPhone(3)}><img src={appIconImg}/></span>
                        ) : (
                            <img onClick={() => setPhone(3)} src={appImg}/>
                        )}    
                        <div>
                            <h4>Pague pelo aplicativo</h4>
                            <p>Pague a conta com um clique! <br />
                            Tudo na palma da sua mão. </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="optionsContainer">
                    <div className="optionsContent">
                        {computer === 4 ? (
                            <span 
                            onClick={() => { 
                                setComputer(4)
                                setPhone(0)
                            }}
                            onMouseOver={() => {
                                setComputer(4)
                                setPhone(0)
                            }}
                            ><img src={establishmentIconImg}/></span>
                        ) : (
                            <img onClick={() => setComputer(4)} src={establishmentImg}/>
                        )}
                        <div>
                            <h4>Cadastre o estabelecimento</h4>
                            <p>Insira as informações sobre seu <br />
                            estabelecimento. </p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {computer === 5 ? (
                            <span onClick={() => { 
                                setComputer(5)
                                setPhone(1)
                            }}
                            onMouseOver={() => {
                                setComputer(5)
                                setPhone(1)
                            }}
                        ><img src={menuIconImg}/></span>
                        ) : (
                            <img onClick={() => setComputer(5)} src={menuImg}/>
                        )}
                        <div>
                            <h4>Insira o cardápio</h4>
                            <p>Assim que cadastrado você <br />
                            poderá edita-lo a hora <br />
                            que quiser.</p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {computer === 6 ? (
                            <span 
                                onClick={() => { 
                                    setComputer(6)
                                    setPhone(2)
                                }}
                                onMouseOver={() => {
                                    setComputer(6)
                                    setPhone(2)
                                }}
                            ><img src={ssolicitationsIconImg}/></span>
                        ) : (
                            <img onClick={() => setComputer(6)} src={solicitaionsImg}/>
                        )}
                        <div>
                            <h4>Acesse as solicitações <br /> de mesa</h4>
                            <p>Ao aceitar o chamado, você pode <br />
                            realizar o check-in do cliente.</p>
                        </div>
                    </div>
                    <div className="optionsContent">
                        {computer === 7 ? (
                            <span 
                                onClick={() => { 
                                    setComputer(7)
                                    setPhone(3)
                                }}
                                onMouseOver={() => {
                                    setComputer(7)
                                    setPhone(3)
                                }}
                            ><img src={requestIconImg}/></span>
                        ) : (
                            <img onClick={() => setComputer(7)} src={requestImg}/>
                        )}    
                        <div>
                            <h4>Gerencie os pedidos</h4>
                            <p>Acesse os produtos selecionados <br />
                            pelos seus clientes e acompanhe a <br /> 
                            realização do pedido.</p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <Container>
            <h1>Como funciona?</h1>
            <div class="container">
                <div
                    className="clients"
                    onClick={() => {
                        setDeviceSelect('clients')
                        setPhone(0);
                    }}
                >
                    <span>Para Clientes</span>
                    {deviceSelect === 'clients' ? (
                        <hr />
                    ) : (
                        ''
                    )}
                </div>
                <div 
                    onClick={() => { 
                        setDeviceSelect('establishments') 
                        setComputer(4);
                    }}
                >
                    <span>Para Estabelecimentos</span>
                    {deviceSelect === 'clients' ? (
                        ''
                    ) : (
                        <hr />
                    )}
                </div>
            </div>
            <Content>
                {renderImage()}
                <Fade right>
                    {renderDevices()}
                </Fade>
            </Content>
        </Container>
    )
}
