import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 150px;

  > img {
    width: 40%;
  }

  @media (max-width: 496px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 96px;

    > img {
      display: table;
      margin: 30px auto;
      width: 80%;
    }
  }
`;

export const ClientsText = styled.div`
> div {
  > div {
      width: 30%;

      span {
      display: block;
      background: #21E270 0% 0% no-repeat padding-box;
      border-radius: 20px 20px 0px 0px;
      height: 8px;
    }

    h5 {
      color: #38E57F;
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
      margin: 10px 0 50px 0;
    }
  }

  p {
    width: 90%;
    margin: 0 auto;
    color: #8E8E93;
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 2vw;
  }
}

  @media (max-width: 798px) {
    > div {
        h3, p {
        font-size: 3vw;
      }
    }
  }

  @media (max-width: 496px) {
    > div {
      > div {
        display: table;
        margin: 0 auto;
      }

      h3, p {
        text-align: center;
        font-size: 5vw;
      }
    }
  }
`;

export const StoresContainer = styled.div`
  margin-top: 60px;

  > img {
    width: 38%;
    margin-right: 10px;
    cursor: pointer;
  }

  @media (max-width: 496px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;