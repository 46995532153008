import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { LandingPage } from '../components/LandingPage/LandingPage';
import { Contact } from '../components/Contact/Contact';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/suporte" component={Contact} />
      </Switch>
    </BrowserRouter>
  );
}