import { createGlobalStyle } from 'styled-components';

import FontsFreeNetSFProTextBold from '../fonts/FontsFree-Net-SFProText-Bold.ttf';
import FontsFreeNetSFProTextBoldItalic from '../fonts/FontsFree-Net-SFProText-BoldItalic.ttf';
import FontsFreeNetSFProTextHeavy from '../fonts/FontsFree-Net-SFProText-Heavy.ttf';
import FontsFreeNetSFProTextLight from '../fonts/FontsFree-Net-SFProText-Light.ttf';
import FontsFreeNetSFProTextMedium1 from '../fonts/FontsFree-Net-SFProText-Medium-1.ttf';
import FontsFreeNetSFProTextMedium from '../fonts/FontsFree-Net-SFProText-Medium.ttf';
import FontsFreeNetSFProTextSemibold1 from '../fonts/FontsFree-Net-SFProText-Semibold-1.ttf';

export default createGlobalStyle`

  @font-face {
    font-family: FontsFreeNetSFProTextBold;
    src: local('FontsFreeNetSFProTextBold'), url(${FontsFreeNetSFProTextBold});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextBoldItalic;
    src: local('FontsFreeNetSFProTextBoldItalic'), url(${FontsFreeNetSFProTextBoldItalic});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextHeavy;
    src: local('FontsFreeNetSFProTextHeavy'), url(${FontsFreeNetSFProTextHeavy});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextLight;
    src: local('FontsFreeNetSFProTextLight'), url(${FontsFreeNetSFProTextLight});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextMedium1;
    src: local('FontsFreeNetSFProTextMedium1'), url(${FontsFreeNetSFProTextMedium1});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextMedium;
    src: local('FontsFreeNetSFProTextMedium'), url(${FontsFreeNetSFProTextMedium});
  }

  @font-face {
    font-family: FontsFreeNetSFProTextSemibold1;
    src: local('FontsFreeNetSFProTextSemibold1'), url(${FontsFreeNetSFProTextSemibold1});
  }


/* Reset */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

:root {
    --main: #21E270;
    --backgroundHeader: #EFEFF4;
    --light-gray: #C7C7CC;
    --gray: #707070;
    --medium-gray: #747474;
    --descripionText: #8E8E93;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    font-family: 'FontsFreeNetSFProTextBold';
  }

  a {
    text-decoration: none;
    color: inherit;
  }


    /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E9E9E9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21E270;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #21E270;
}


  html, h1 {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  @keyframes entering{
    0%  {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }

`;
