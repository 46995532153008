import styled from 'styled-components';

export const Container = styled.div`
    > h2 {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 28px;
        color: var(--main);
        text-align: center;
        margin-bottom: 80px;
    }
`;

export const CardContainer = styled.div`
    padding-right: 22px;
    padding-left: 22px;
    cursor: grab;
`;

export const Card = styled.div`
    height: 213px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 20px #00000029;
    border-radius: 20px;
    border-top: 8px solid var(--main);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
        display: table;
        margin: 10px auto;
    }

    h3 {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 15px;
        text-align: center;
        margin: 20px 0 30px;
        color: var(--descripionText);
    }
    
    p {
        width: 90%;
        margin: 0 auto;
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 15px;
        text-align: center;
        color: var(--light-gray);
    }

    @media (max-width: 652px) {
        height: 209px;
    }

`;